/* nav */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
    --emphasis-color: #000000;
    --emphasis-color--dark: #000000;
    --color: #333333;
    --color-bold: "#000000";
    font-size: 12pt;
    --link-color: #cccccc;
    --link-selected: #c799de;
    --link-selected: #ef4b3c;
    --link-selected: #ef3c57;
    --link-selected: #f37c7c;
    --link-hover: #e1baf5;
    --link-hover: #e37e6c;
    --link-hover: #ef6378;
    --link-hover: #f39d9d;
}

body {
    font-family: Nunito;
    letter-spacing: 0.2pt;
}

h1, h2, h3, h4, p {
    font-family: Nunito;
}

/*#region navbar */

.navbar__link__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.navbar__link__logo__image {
    width: 30px;
    margin-right: 5px;
}

.navbar__link__logo__span {
    color: #ffffff;
    font-weight: 500;
}

.header__nav__link {
    color: var(--link-color);
    text-decoration: none;
    font-size: 10pt;
    margin-right: 15px;
}

.header__nav__link--active {
    color: var(--link-selected);
    text-decoration: none;
    font-size: 10pt;
    margin-right: 15px;
    padding-bottom: 15px;
    /* border-bottom: 4px solid var(--link-selected); */
}

.header__nav__link:hover, .header__nav__link--active:hover {
    color: var(--link-hover);
}

/*#endregion */

/*#region footer */

.footer {
    display: flex;
    flex-direction: row;
    background-color: #212429;
    padding-top: 10px;
    padding-left: 20px;
    justify-content: space-between;
}

.footer__p {
    color: #cccccc;
    font-size: 10px;
}

.footer__div {
    display: flex;
    flex-direction: row;
}

.footer__nav__link {
    color: var(--link-color);
    text-decoration: none;
    font-size: 8pt;
    margin-right: 15px;
}

.footer__nav__link--active {
    color: var(--link-selected);
    text-decoration: none;
    font-size: 8pt;
    margin-right: 15px;
}

.footer__nav__link:hover, .footer__nav__link--active:hover {
    color: var(--link-hover);
}

/*#endregion */

/*#region welcomeheadline */
.welcomeheadline {
    text-align: center;
    font-family: Nunito;
    font-size: 12pt;
    margin: 50px;
}

.welcomeheadline__p {
    color: #333333;
    margin-bottom: 0;
}

.welcomeheadline__p__span--bold {
    font-weight: 700;
    color: var(--emphasis-color);
}

/*#endregion */

/*#region welcomecarousel */
.welcomecarousel {}

/*color*/
.welcomecarousel__carouselitem {
    width: 100p;
    height: 300px;
    background-color: #eeeeee;
    align-items: center;
    border-radius: 5;
}

.welcomecarousel__carouselitem--pink {
    background-color: pink;
    background: rgb(246, 194, 195);
    background: linear-gradient(20deg, rgba(246, 194, 195, 1) 0%, rgba(252, 212, 198, 1) 100%);
    background-size: fill;
}

.welcomecarousel__carouselitem--blue {
    background-color: pink;
    background: rgb(252, 212, 198);
    background: linear-gradient(20deg, rgba(252, 212, 198, 1) 0%, rgba(246, 194, 224, 1) 100%);
    background-size: fill;
}

.welcomecarousel__carouselitem--green {
    background-color: pink;
    background: rgb(246, 194, 224);
    background: linear-gradient(20deg, rgba(246, 194, 224, 1) 0%, rgba(252, 212, 221, 1) 100%);
    background-size: fill;
}

.welcomecarousel__carouselitem--purple {
    background-color: pink;
    background: rgb(252, 212, 221);
    background: linear-gradient(20deg, rgba(252, 212, 221, 1) 0%, rgba(229, 212, 252, 1) 100%);
    background-size: fill;
}

.welcomecarousel__carouselitem--peach {
    background-color: pink;
    background: rgb(229, 212, 252);
    background: linear-gradient(20deg, rgba(229, 212, 252, 1) 0%, rgba(246, 194, 195, 1) 100%);
    background-size: fill;
}

.welcomecarousel__carouselitem__div {
    display: flex;
    flex-direction: column;
    margin-left: 15%;
    justify-content: center;
    height: 100%;
    width: 70%;
}

.welcomecarousel__carouselitem__div__p {
    font-size: 16px;
    text-align: center;
    color: #333333;
}

.welcomecarousel__carouselitem__div__p__span--bold {
    color: var(--emphasis-color);
    font-weight: 700;
}

/*#endregion */

/*#region welcomebenefitcard */
.welcomebenefitcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.welcomebenefitcard__card {
    width: 18rem;
    margin: 10px;
}

.welcomebenefitcard__card__title {
    font-size: 16pt;
    font-weight: 500;
    color: var(--emphasis-color--dark);
    padding-bottom: 10px;
}

.welcomebenefitcard__card__text__p {
    font-size: 11pt;
    font-weight: 300;
    color: var(--color);
    margin-bottom: 5px;
}

.welcomebenefitcard__card__text__p__span--bold {
    font-weight: 700;
    color: var(--emphasis-color);
}

/*#endregion */

/*#region welcomeSubscription */
.welcomesubscription {
    background-color: rgb(212, 206, 224);
    padding: 30px;
    text-align: center;
}

.welcomesubscription__div {
    display: flex;
    flex-direction: row;
    padding: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.welcomesubscription__div__card {
    width: 90%;
    margin: 10px;
    font-size: 10pt;
    border-radius: 7px;
    padding: 15px;
}

.welcomesubscription__div__text {
    margin-top: 20px;
}

.welcomesubscription__div__text__p__span--bold {
    color: var(--emphasis-color);
    font-weight: 700;
}

.welcomesubscription__div__appstorelogos {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.welcomesubscription__div__appstorelogos_img {
    margin: 5px;
    height: 30px;
    cursor: pointer;
}

/*#endregion */

/*#region welcomeContactOptions */
.welcomecontactoptions {
    padding: 0px;
    text-align: center;
}

/*#endregion */

/*#region contactForm */
.contactform__div__background {
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 8%, rgba(245, 245, 245, 1) 8%, rgba(245, 245, 245, 1) 92%, rgba(0, 0, 0, 1) 92%, rgba(0, 0, 0, 1) 100%);
    padding: 15px;
    border-radius: 10px;
}

/*#endregion */

/*#region socialChannels */

.socialchannels {
    background-color: #f5f5f5;
}

.socialchannels__row__col__div {
    text-align: left;
    padding-top: 10px;
}

.socialchannels__row__col__div__media {
    display: inline-block;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.socialchannels__row__col__div__media__div {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 10pt;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 10px;
}

.socialchannels__row__col__div__media__div:hover {
    color: purple
}

.socialchannels__row__col__div__media__div__img {
    height: auto;
    width: 30px;
    margin-right: 10px;
}

.socialchannels__row__col__div__media__div__p {
    margin: 0;
}

/*#endregion */

/*#region policies */
.policies__nav {
    margin-bottom: 10px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid purple;
    position: sticky;
    top: 56px;
}

.policies__nav__navlink {
    color: #000000;
    text-decoration: none;
    padding: 10px;
    font-size: 10pt;
}

.policies__nav__navlink--active {
    color: purple;
    text-decoration: none;
    padding: 10px;
    font-size: 10pt;
}

.policies__nav__navlink:hover, .policies__nav__navlink--active:hover {
    color: var(--link-selected);
}

/*#endregion */

/*#region xs < 576 */
@media screen and (min-width: 576px) {
    .welcomebenefitcard__card {
        width: 4rem;
        margin: 10px;
    }

    .welcomesubscription__div__card {
        width: 40%;
    }
}

/*#endregion */

/*#region sm > 576 */
@media screen and (min-width: 576px) {
    .welcomebenefitcard__card {
        width: 80%;
        margin: 10px;
    }
}

/*#endregion */

/*#region md < 768 */
@media screen and (min-width: 768px) {
    .welcomebenefitcard {
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
    }

    .welcomebenefitcard__card {
        width: 14rem;
        margin: 10px;
    }

    .welcomesubscription__div__appstorelogos_img {
        height: 45px;
    }

    .welcomecarousel__carouselitem--pink {
        background-color: pink;
        background: rgb(246, 168, 170);
        background: linear-gradient(90deg, rgba(246, 194, 195, 1) 0%, rgba(252, 212, 198, 1) 100%);
        background-size: fill;
    }

    .welcomecarousel__carouselitem--blue {
        background-color: pink;
        background: rgb(246, 168, 170);
        background: linear-gradient(90deg, rgba(252, 212, 198, 1) 0%, rgba(246, 194, 224, 1) 100%);
        background-size: fill;
    }

    .welcomecarousel__carouselitem--green {
        background-color: pink;
        background: rgb(246, 168, 170);
        background: linear-gradient(90deg, rgba(246, 194, 224, 1) 0%, rgba(252, 212, 221, 1) 100%);
        background-size: fill;
    }

    .welcomecarousel__carouselitem--purple {
        background-color: pink;
        background: rgb(246, 168, 170);
        background: linear-gradient(90deg, rgba(252, 212, 221, 1) 0%, rgba(229, 212, 252, 1) 100%);
        background-size: fill;
    }

    .welcomecarousel__carouselitem--peach {
        background-color: pink;
        background: rgb(246, 168, 170);
        background: linear-gradient(90deg, rgba(229, 212, 252, 1) 0%, rgba(246, 194, 195, 1) 100%);
        background-size: fill;
    }
}

/*#endregion */

/*#region lg < 992 */
@media screen and (min-width: 992px) {
    .welcomebenefitcard__card {
        width: 18rem;
        margin: 10px;
    }

    .welcomesubscription__div__card {
        width: 18rem;
    }

    .welcomesubscription__div {
        flex-wrap: nowrap;
    }
}

/*#endregion */

/*#region xl < 1200 */
/*#endregion */

/*#region xxl < 1400 */
/*#endregion */

.form-floating:focus-within {
    white-space: nowrap !important; 
    overflow: hidden !important;
}

.form-floating {
    outline: none !important;
}
.form-group {
    outline: 2px solid red !important;
}