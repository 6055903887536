/* @media only screen and (min-width : 481px) {
    .flex-row {
        display: flex;
        flex-wrap: wrap;
    }
    .flex-row > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
    .flex-row.row:after, 
    .flex-row.row:before {
        display: flex;
    }
} */

@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.blog__first__paragraph {
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog__introduction {
    --bgcolor: #eeeeee;
    --bgcolor: rgb(240, 190, 168);
    --bggradient: linear-gradient(90deg, rgba(230, 178, 207, 1) 0%, rgb(193, 130, 190) 100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bgcolor);
    background: var(--bggradient);
    min-height: 150px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 0px;
    margin-top: 0px;

    background-image: url('../../../public/assets/desk.jpg');
    background-size: cover;
    background-position: bottom;
}

.home__content {
    margin: 10px 0px;
}

.blogpost__author__pill {
    display: inline;
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 10px;
    background-color: rgb(167, 89, 234);
    color: #eeeeee;
}

.no-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.blogpost__banner {
    --me: url('../../../public/assets/blogs/iphone2.jpeg');
    /* --me: url('../../assets/blog-pregnant.jpeg'); */
    background-image: var(--me);
    background-size: cover;
    background-position: center;
}

.blogpost__body {
    font-size: 18px;
}



.blogpost__quote {
    font-style: italic;
    font-family: serif;
    font-family: 'Lobster Two', cursive;
    font-family: 'Josefin Sans', sans-serif;
    
    /* background-color: #f1dbf1; */
    text-align: center;
    color: rgb(96, 44, 96);
    color: rgb(167, 89, 234);

    color: rgb(80, 58, 99);
    font-weight: 200;
    font-size: 32px;
    padding: 30px 0px;
    margin-top: 30px;
    margin-bottom: 50px;
    border-top: 1px solid rgb(80, 58, 99);
    border-bottom: 1px solid rgb(80, 58, 99);
}

.blogpost__share {
    border-radius: 10px;
    background-color: #fafafa;
}

.blog__details {
    margin-top: 10px;
}

.blog__details p {
    font-size: 18px;
    margin-bottom: 30px;
}
.blog__details blockquote {
    font-style: italic;
    font-family: 'Josefin Sans', sans-serif;
    
    /* background-color: #f1dbf1; */
    text-align: center;
    padding: 30px 0px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 50px;
    border-top: 1px solid rgb(166, 136, 193);
    border-bottom: 1px solid rgb(166, 136, 193);
}

.Blog__Post__Share__Icon {
    margin-right: 7px;
}
.Blog__Post__Share__Icon:last-child {
    margin-right: 0px;
}
.blog__details blockquote p {
    font-family: 'Lobster Two', cursive;
    font-family: 'Josefin Sans', sans-serif;
    
    /* background-color: #f1dbf1; */
    text-align: center;
    color: rgb(96, 44, 96);
    color: rgb(167, 89, 234);

    color: rgb(80, 58, 99);
    font-weight: 200;
    font-size: 16px;
    padding: 0;
    margin: 0;
}
.blog__details blockquote h2 {
    font-family: 'Lobster Two', cursive;
    font-family: 'Josefin Sans', sans-serif;
    
    /* background-color: #f1dbf1; */
    text-align: center;
    color: rgb(96, 44, 96);
    color: rgb(167, 89, 234);
    margin: 0;

    color: rgb(80, 58, 99);
    font-weight: 200;
    font-size: 28px;
}



.blogpost__pubcast {
    border-radius: 10px;
    color: #eeeeee;
    background-color: #111111;

    background-image: url('../../../public/assets/research.webp');
    background-position: right;
    background-size: cover;
    background-blend-mode:overlay;
}

.Blogpost__Publett {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    --bggradient: linear-gradient(90deg, rgba(240, 190, 168, 1) 0%, rgba(230, 178, 207, 1) 100%);
    background: var(--bggradient);
}

.blogpost__share__body {
    padding: 5px 15px;
    align-items: center;
}

.icon_bg {
    fill: green;
}

.icon_bg:hover {
    fill: red;
}
.Blogpost__More__Posts {
    padding-top: 10px;
    padding-bottom: 10px;
}
.Blogpost__More__Post__Title {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: var(--primaryFontBodyColor);
    font-size: var(--mdFontSize);
    line-height: var(--smLineHeight);
    word-spacing: var(--smWordSpacing);
    display: -webkit-box;
    margin-top: 3px;
    margin-bottom: 0;
    overflow: hidden;
}
.blogpost__thumbnail {
    /* --m/e: url('../../assets/iphone3.jpg'); */
    /* --me2: '../../assets/iphone3.jpg'; */
    background-size:cover;
    /* width: 100%; */
    /* min-width: 100%; */
    /* height: 100%; */
    /* min-height: 100%; */
    background-image: var(--me);
    background-position: center;
    border-radius: 5px;
}

.home__subscription__container {
    background-color: rgb(242, 215, 188);
}

@media only screen and (min-width : 768px) {
    .home__content {
        margin: 30px 0px;
    }
    .blog__introduction {
        
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media only screen and (min-width : 992px) {
    .home__content {
        margin: 50px 0px;
    }
}