@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.Pubcastdetail__Title {
    font-size: 22px;
}
.Pubcastdetail__Subtitle {
    font-size: 18px;
}
.Pubcastdetail__Summary {
    font-size: 14px;
    margin-top: 10px;
}
.Pubcastdetail__Return__Block {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.Pubcastdetail__Return__Icon {
    height: 12px;
    padding-right: 6px;
}
.Pubcastdetail__Return {
    text-decoration: none;
    color: var(--secondaryLinkSelectedColor);
    background-color: var(--secondaryLinkSelectedColor);
    border-radius: 25px;
    padding: 3px 15px 3px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Pubcastdetail__Return:hover {
    text-decoration: none;
    color: var(--secondaryLinkHoverColor);
    background-color: var(--secondaryLinkHoverColor);
}
.Pubcastdetail__Return__Text{
    color: #ffffff;
    font-size: 12px;
    display: inline-block;
    margin: 0px;
    padding: 0px;
}
.Pubcastdetail__Text {
    font-size: 14px;
}
.Pubcastdetail__Hosts {
    font-size: 12px;
}
.Pubcastdetail__Text a {
    color: var(--secondaryLinkSelectedColor);
}
.Pubcastdetail__Text a:hover {
    color: var(--secondaryLinkHoverColor);
}
.Pubcastdetail__Block {
    margin-top: 20px;
}
.Pubcastdetail ul, li {
    list-style-type: disc;
}