.Blog__Card__Body {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: var(--primaryFontBodyColor);
    display: -webkit-box;
    font-size: var(--mdFontSize);
    font-weight: var(--smFontWeight);
    line-height: var(--smLineHeight);
    overflow: hidden;
    word-spacing: var(--xsFontWordSpacing);
}

.Blog__Card__Footer {
    background-color: #ffffff;
    border-top: none;
    color: #f37c7c;
    font-size: var(--smFontSize);
    margin-bottom: 5px;
    text-align: right; 
}

.Blog__Card__Title {
    color: var(--primaryFontBodyColor);
    font-size: var(--lgFontSize);
    font-weight: var(--mdFontWeight);
    line-height: var(--mdLineHeight);
    word-spacing: var(--xsFontWordSpacing);
}


.Blog__Introduction {
    --bgcolor: rgb(240, 190, 168);
    background-color: var(--bgcolor);
    background: var(--primaryBackgroundGradient);
    background-image: url('../../../public/assets/desk.jpg');
    background-position: bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 300px;
}

.Blog__Section {
    margin-bottom: 20px;
    margin-top: 20px;
}

.Blog__Section__Title {
    color: var(--primaryFontBodyColor);
    font-size: var(--h3FontSize);
    font-weight: var(--smFontWeight);
    /* margin-bottom: 20px; */
    margin-top: 20px;
}

@media only screen and (min-width : 576px) {
}

@media only screen and (min-width : 768px) {
}

@media only screen and (min-width : 992px) {
}

@media only screen and (min-width : 1200px) {
}