.subscriptions{
    /* --bgcolor: #cccccc; */
    /* background-color: var(--bgcolor); */
    /* height: 400px; */
    padding: 30px;
}

.Subscriptions__Footer {
    border-radius: 0px;
    margin-top: 10px;
    padding: 15px;
    background-color: rgba(196, 60, 60, 0.2);
    font-size: 12px;
}
.Subscriptions__Footer p{
    margin: 0;
    padding: 0;
}

.Subscription__New__Pill {
    background-color: rgb(167, 89, 234) !important;
}

.Subscription__ComingSoon__Pill {
    background-color: #f37c7c !important;
}