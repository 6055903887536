.Pubcastheader__Introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Pubcastheader__Introduction__Subtitle {
    font-size: var(--lgFontSize);
    font-weight: var(--xsFontWeight);
    color: #eeeeee;
}

.Pubcastheader__Introduction__Title {
    font-size: var(--h1FontSize);
    font-weight: var(--smFontWeight);
    margin-bottom: 20px;
    color: #eeeeee;
}

.Pubcastheader__PodcastApps{
    --fontcolor: #eeeeee;
    margin-top: 20px;
    font-size: 1em;
    font-weight: 300;
    color: var(--fontcolor);
}

.Pubcast__Header {
    --bgcolor: #222222;
    background-color: var(--bgcolor);
    background-image: url('../../../public/assets/research.webp');
    background-position: right;
    background-size: cover;
    background-blend-mode: overlay;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 300px;
}

@media (min-width:576px) {}

@media (min-width:768px) {
    .Pubcastheader__Introduction {
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

}

@media (min-width:992px) {}

@media (min-width:1200px) {}