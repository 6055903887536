.Pubcast__Resourceslink__Block {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.Pubcast__Resourceslink__Icon {
    height: 12px;
    padding-left: 6px;
}
.Pubcast__Resourceslink {
    text-decoration: none;
    color: var(--secondaryLinkSelectedColor);
    background-color: var(--secondaryLinkSelectedColor);
    border-radius: 25px;
    padding: 3px 15px 3px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.Pubcast__Resourceslink:hover {
    text-decoration: none;
    color: var(--secondaryLinkHoverColor);
    background-color: var(--secondaryLinkHoverColor);
}
.Pubcast__Resourceslink__Text{
    color: #ffffff;
    font-size: 12px;
    display: inline-block;
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
}