
.publettapp {
    --bgcolor: #ffffff;
    background-color: var(--bgcolor);
    height: 100%;
    padding: 30px;
    border-radius: 5px; 
}
.publettapp__header {
    margin-bottom: 2em; 
}
.publettapp__title {
    font-size: 2em;
    font-weight: 600;
    color: #444444;
    margin: 0;
}
.publettapp__title--thin {
    font-weight: 300;
    color: #666666;
    margin: 0;
}
.publettapp__subtitle {
    margin-top: 30px;
    font-size: 1.2em;
    font-weight: 300;
    color: #444444;
}
.publettapp__h3 {
    font-size: 1.4em;
    font-weight: 500;
    color: #444444;
    margin-top: 1.5em;
    margin-bottom: .5em;
}