.socialmedia {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 30px 20px;
    /* height: 600px; */
    /* max-height: 600px; */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.socialmedia__icons {
    /* margin-top: 1em; */
    text-align: center;
}

.socialmedia__icon__link {
    width: 40px;
    padding: 8px;
    cursor: pointer;
}

.socialmedia__ig__link {
    font-size: .7em;
    text-align: right;
    /* background-color: #eeeeee; */
    /* border-radius: 0px 0px 5px 5px; */
    color: #444444;
    padding: 10px;
}

.socialmedia__post {
    border-radius: 5px 5px 5px 5px;
    display: block;
    margin: 0;
    padding: 0;
    object-fit: contain;
    max-height: 300px;
    max-width: 300px;
}

.socialmedia__subtitle {
    --fontcolor: #333333;
    --fontcolor: #8c307e;
    color: var(--fontcolor);
    font-size: 1.2em;
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
}

.socialmedia__message {
    --fontcolor: #333333;
    color: var(--fontcolor);
    font-size: 1em;
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
    margin-top: 15px;
}

.socialmedia__title {
    --fontcolor: #111111;
    color: var(--fontcolor);
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
    margin-bottom: .5em;
    text-align: center;
}

@media only screen and (min-width : 992px) {
    .socialmedia {
        height: 600px;
    }
}
