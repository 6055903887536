/* @media only screen and (min-width : 481px) {
    .flex-row {
        display: flex;
        flex-wrap: wrap;
    }
    .flex-row > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
    .flex-row.row:after, 
    .flex-row.row:before {
        display: flex;
    }
} */

.home__content {
    margin: 10px 0px;
}

.home__introduction {
    --bgcolor: #eeeeee;
    --bgcolor: rgb(240, 190, 168);
    --bggradient: linear-gradient(90deg, rgba(240, 190, 168, 1) 0%, rgba(230, 178, 207, 1) 100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bgcolor);
    background: var(--bggradient);
    /* height: 400px; */
    margin-bottom: 0px;
    margin-top: 0px;
    background-image: url('../../../public/assets/intro_sm.webp');
    background-size: cover;
    background-position: top center;
    /* background-position: right; */
}

.home__subscription__container {
    --bgcolor: #eeeeee;
    --bgcolor: rgb(240, 190, 168);
    --bggradient: linear-gradient(90deg, rgba(240, 190, 168, 1) 0%, rgba(230, 178, 207, 1) 100%);
    background-color: rgb(242, 215, 188);
    background-color: var(--bgcolor);
    background: var(--bggradient);
}

@media only screen and (min-width : 768px) {
    .home__content {
        margin: 30px 0px;
    }
    .home__introduction {
        background-image: url('../../../public/assets/intro.jpg');
        background-position: right center;
    }
}

@media only screen and (min-width : 992px) {
    .home__content {
        margin: 50px 0px;
    }
    .home__introduction {
        background-position: right center;
        background-image: url('../../../public/assets/intro_lg.webp');
        background-position: right center;
    }
}

@media only screen and (min-width : 1200px) {
    .home__content {
        margin: 50px 0px;
    }
    .home__introduction {
        background-position: right center;
        background-image: url('../../../public/assets/intro_xl.webp');
        background-position: right center;
    }
}
