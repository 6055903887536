.Policies__Active__Link {
    /* color: #f37c7c !important; */
    display: inline;
    text-decoration: none;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    padding: 4px 10px;
    font-size: 14px;
    background-color: #f37c7c;
    color: #ffffff !important;
    margin-bottom: 7px;
    margin-right: 10px;
}
.Policies__Link {
    color: #333333;
    text-decoration: none;
    padding: 4px 10px;
    font-size: 14px;
    margin-bottom: 7px;
    margin-right: 10px;
}
.Policies__Link:hover {
    color: #f39d9d !important;
}
.Policies__Nav__Bar {
    border-bottom: 1px solid #666666;
}