/* nav */
/*#region welcomebenefitcard */
.appbenefit {
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
    background-color: #fafafa;
    padding: 30px;
    border-radius: 10px;
}

.appbenefit__card {
    width: 6rem;
    margin: 0px;
    background-color: transparent; 
}

.appbenefit__card__title {
    font-size: 1.2em;
    font-weight: 500;
    padding-top: 5px;
    color: var(--emphasis-color--dark);
    
    /* padding-bottom: 10px; */
}

.appbenefit__card__title2 {
    font-size: 1em;
    font-weight: 500;
    color: var(--emphasis-color--dark);
    /* padding-bottom: 10px; */
}

.appbenefit__card__text__p {
    font-size: 11pt;
    font-weight: 300;
    color: var(--color);
    margin-bottom: 20px;
}

.appbenefit__card__text__p__span--bold {
    font-weight: 700;
    color: var(--emphasis-color);
}

/*#endregion */

/*#region xs < 576 */
@media screen and (min-width: 576px) {
    .appbenefit__card {
        width: 4rem;
        margin: 10px;
    }
}

/*#endregion */

/*#region sm > 576 */
@media screen and (min-width: 576px) {
    .appbenefit__card {
        width: 80%;
        margin: 10px;
    }
}

/*#endregion */

/*#region md < 768 */
@media screen and (min-width: 768px) {
    .appbenefit {
        /* flex-direction: row; */
        /* justify-content: center; */
        /* align-items: stretch; */
    }

    .appbenefit__card {
        width: 14rem;
        margin: 10px;
    }
    
    .appbenefit__card__title {
        padding-top: 0px;
    }
}

/*#endregion */

/*#region lg < 992 */
@media screen and (min-width: 992px) {
    .appbenefit__card {
        width: 10rem;
        margin: 10px;
    }
    .appbenefit__card__text__p {
        margin-bottom: 0px
    }
}

/*#endregion */

/*#region xl < 1200 */
/*#endregion */

/*#region xxl < 1400 */
/*#endregion */

.form-floating:focus-within {
    white-space: nowrap !important; 
    overflow: hidden !important;
}

.form-floating {
    outline: none !important;
}
.form-group {
    outline: 2px solid red !important;
}