.submitapaper {
    --bgcolor: #ffffff;
    --bggradient: linear-gradient(30deg, rgba(241,202,185,1) 0%, rgb(244, 221, 212) 100%);
    background-color: var(--bgcolor);
    padding: 30px;
    border-radius: 5px;
    background-image: url('../../../public/assets/paper-plane_temp.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.submitapaper__header__title {
    --fontcolor: #8c307e;
    --fontcolor: #794b65;
    --fontcolor: #111111;
    font-size: 1.5em;
    font-weight: 600;
    color: var(--fontcolor);
}
.submitapaper__header__subtitle {
    --fontcolor: #8c307e;
    /* --fontcolor: #794b65; */
    /* --fontcolor: #333333; */
    font-size: 1em;
    font-weight: 300;
    margin-bottom: 1em;
    color: var(--fontcolor);
}
.submitapaper__link {
    background-color: #ef3c57;
    background-color: #f37c7c;
    color: #ffffff;
    padding: 5px 15px;
    text-decoration: none;
    border-radius: 10px;
}
.submitapaper__link:hover {
    background-color: #ef3c57;
    background-color: #f37c7c;
    color: #ffffff;
}

.submitapaper__body {
    --fontcolor: #555555;
    font-size: 1em;
    font-weight: 300;
    color: var(--fontcolor);
    margin-bottom: 1em;
}
.submitapaper__body__link {
    --fontcolor: #8c307e;
    --fontcolor: #794b65;
    font-size: 1em;
    font-weight: 300;
    color: var(--fontcolor);
    text-decoration: none;
}
.btn-dark-outline {
    /* background-color: #6a1d48 !important; */
    border: 1px solid #8c307e !important;
    color: #8c307e !important;
}
.btn-dark-outline:hover {
    background-color: #8c307e !important;
    border: 1px solid #8c307e !important;
    color: #ffffff !important;
}