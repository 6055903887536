.Behindtheapp {
    background-color: #f5f5f5;
    background-image: url('../../../public/assets/behind_the_app.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.Behindtheapp__Introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.introduction__appstore {
    background-color: #cccccc;
    padding: 20px;
}

.Behindtheapp__Title {
    font-size: var(--h1FontSize);
    font-weight: var(--smFontWeight);
    margin-bottom: 20px;
}

.Behindtheapp__Subtitle {
    font-size: var(--lgFontSize);
    font-weight: var(--xsFontWeight);
}

@media (min-width:576px) {}

@media (min-width:768px) {
    .Behindtheapp__Introduction {
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:992px) {}

@media (min-width:1200px) {}