.Behindtheapp__Blockquote {
    color: #685cc9;
    font-family: Comforter;
    font-size: var(--xlFontSize);
    font-weight: var(--xsFontWeight);
    letter-spacing: var(--smFontLetterSpacing);
    padding-left: 15px;
    padding-right: 15px;
    transform: rotate(-1deg);
    word-spacing: var(--smFontWordSpacing);
}

.Behindtheapp__External__Link {
    background-color: var(--primaryActionBackgroundColor);
    border: none;
    border-radius: var(--smBorderRadius);
    color: var(--primaryActionLabelColor);
    padding: 5px 15px;
    text-decoration: none !important;
}

.Behindtheapp__Article {
    align-items: center;
    display: flex;
    padding-bottom: 40px;
    padding-top: 40px;
}

.Behindtheapp__Article--Dark {
    background-color: var(--backgroundColorDark);
}

.Behindtheapp__Article__Body {
    color: var(--primaryFontBodyColor);
    font-size: var(--mdFontSize);
    font-weight: var(--smFontWeight);
    line-height: var(--mdLineHeight);
    word-spacing: var(--xsFontWordSpacing);
}

.Behindtheapp__Article__Body--Strong {
    font-weight: var(--lgFontWeight);
}

.Behindtheapp__Article__Image {
    /* background-color: var(--primaryImageBackgroundColor); */
    border-radius: var(--mdBorderRadius);
    display: inline-block;
    padding: 5px;
    width: auto;
}

.Behindtheapp__Article__Image--Amber {
    background: linear-gradient(0deg, rgba(255, 211, 191,1) 0%, rgba(255, 211, 191,1) 50%, var(--primaryImageBackgroundColor) 50%, var(--primaryImageBackgroundColor) 100%);
}

.Behindtheapp__Article__Image--Hidden {
    display: none;
}

.Behindtheapp__Article__Image--Left {
    display: flex;
    float: left;
}

.Behindtheapp__Article__Image--Peach {
    background: linear-gradient(180deg, rgba(255, 198, 191, 1) 0%, rgba(255, 198, 191, 1) 50%, var(--primaryImageBackgroundColor) 50%, var(--primaryImageBackgroundColor) 100%);
}

.Behindtheapp__Article__Image--Pink {
    background: linear-gradient(90deg, rgba(255, 223, 244,1) 0%, rgba(255, 223, 244,1) 50%, var(--primaryImageBackgroundColor) 50%, var(--primaryImageBackgroundColor) 100%);
}

.Behindtheapp__Article__Image--Purple {
    background: linear-gradient(270deg, rgba(187, 180, 255,1) 0%, rgba(187, 180, 255,1) 50%, var(--primaryImageBackgroundColor) 50%, var(--primaryImageBackgroundColor) 100%);
}

.Behindtheapp__Article__Image--Right {
    display: flex;
    float: right;
}

.Behindtheapp__Article__Image__Direction {
    cursor: pointer;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 5px;
}

.Behindtheapp__Article__Image__Direction--Disabled {
    cursor:auto;
}

.Behindtheapp__Article__Image__Direction--Left {
    margin-left: 15px;
}

.Behindtheapp__Article__Image__Direction--Right {
    margin-right: 15px;
}

.Behindtheapp__Article__Image__Link {
    color: var(--secondaryFontBodyColor);
    display: flex;
    flex-direction: row;
    font-size: var(--smFontSize);
    justify-content: center;
    margin-top: 5px;
    padding-top: 10px;
}

.Behindtheapp__Article__Image__Size {
    border-radius: var(--smBorderRadius);
    width: 80px;
}

.Behindtheapp__Article__Title {
    color: var(--primaryFontBodyColor);
    font-size: var(--h2FontSize);
    font-weight: var(--smFontWeight);
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 0px;
    word-spacing: var(--xsFontWordSpacing);
}

.Behindtheapp__Article__Title--Peach {
    color: #ff836a;
}

.Behindtheapp__Article__Title--Purple {
    color: #685cc9;
}

.Behindtheapp__Article__Title--Pink {
    color: #b56d94;
}

.Behindtheapp__Team__Title {
    font-family: Comforter;
    font-size: var(--superFontSize);
    font-weight: var(--smFontWeight);
    /* color: '#f35a81'; */
    padding-bottom: 10px;
    padding-top: 0px;
    margin-bottom: 20px;
    transform: rotate(-3deg);
    word-spacing: var(--smFontWordSpacing);
}

.Behindtheapp__Team__Wrap {
    background: var(--primaryBackgroundGradient);
    padding-bottom: 40px;
    padding-top: 40px;
}

@media screen and (min-width: 576px){
    .Behindtheapp__Article__Image {
        padding: 10px;
    }
}

@media screen and (min-width: 768px) {

    .Behindtheapp__Article__Image {
        padding: 15px;
    }

    .Behindtheapp__Article__Image--Left,
    .Behindtheapp__Article__Image--Right {
        /* color: var(--primaryFontBodyColor); */
        display: none;
    }
    .Behindtheapp__Article__Image--Hidden {
        display: flex;
    }
    
    .Behindtheapp__Article__Image__Size {
        width: 100%;
    }
}

@media screen and (min-width: 992px){
    .Behindtheapp__Article__Image {
        padding: 20px;
    }
}

@media screen and (min-width: 1200px){   
}