.blog {
    --bgcolor: #ffffff;

    background-color: var(--bgcolor);
    border-radius: 5px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
}

.blog__image{
    height: 120px;
    max-height: 120px;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    /* margin-top: 15px; */
    /* margin-bottom: 15px; */
}


.blog__author {
    --fontcolor: #111111;

    color: var(--fontcolor);
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 1em;
}

.blog__article__title {
    --fontcolor: #333333;
    /* --fontcolor: #8c307e; */
    color: var(--fontcolor);
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
}

.blog__readmore {
    background-color: #eeeeee;
    text-decoration: none;
    --fontcolor: #8c307e;
    --fontcolor: #e37e6c;
    --fontcolor: #f16053;
    --fontcolor: #ef4b3c;
    --fontcolor: #ef3c57;
    --fontcolor: #f37c7c;   
    color: var(--fontcolor);   
}
.blog__readmore:hover {
    --fontcolor: #f37c7c;   
    color: var(--fontcolor);   
}
.blog__content__title {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    overflow: hidden;
    background-color: #fafafa;
    border-top: 15px solid #fafafa;
    border-bottom: 15px solid #fafafa;
    border-radius: 0px 0px 5px 5px;
    padding: 0px 15px;
}

.blog__content {
    /* flex: 1 1 auto; */
    /* height: 30%; */
    /* background-color: blue; */
    /* flex-grow: 1; */
    /* flex-grow: 1; */
    /* overflow: hidden; */
    /* max-height: 100%; */
    /* display: flex; */
    flex: 1 1 auto;
    background-color: red;
    padding-right: 3px;
    overflow: hidden;
    height: 100%;
}

.blog__date {
    --fontcolor: #111111;

    color: var(--fontcolor);
    font-size: .7em;
    font-weight: 200;
    margin: 0;
}
.blog__moreblogs {
    text-decoration: none;
    padding: 5px 20px;
    font-size: 1em;
    font-weight: 300;
    margin-top: 20px;
    --fontcolor: #ef4b3c;
    --fontcolor: #ef3c57;
    --fontcolor: #f37c7c;
    color: #ffffff;
    background-color: var(--fontcolor);
    border-radius: 10px;
}
.blog__moreblogs:hover {
    --fontcolor: #ef4b3c;
    --fontcolor: #f37c7c;
    color: #ffffff;
    background-color: var(--fontcolor);
    
}

.blog__intro {
    /* flex: 1 1 auto; */
    /* height: 100px; */
    /* overflow: hidden; */
    /* background-color: green; */
    background-color: pink;
    /* height: inherit; */
    position: absolute;
    min-height: 50px;
    overflow: hidden;
}
.blog__intro__container {
    /* position: absolute; */
    height: 100%;
    min-height: 300px;
    /* width: 100%; */
    overflow: hidden;
    /* height: calc(100% - 1.5rem); */
}

.blog__intro__text {
    /* display: -webkit-box !important; */
    /* -webk    it-line-clamp: 2; */
    /* overflow: hidden; */
    /* -webkit-box-orient: vertical; */
    /* white-space: normal; */
    /* top: -1px;
            right: -1px;
            left: -1px;
            bottom: -1px;
            font-size: 80%;
            position: absolute;
            text-overflow: ellipsis;
            overflow: hidden; */
    /* background-color: yellow; */
    /* flex: 1 1 auto; */
    /* background-color: blue; */
    height: 300px;
    max-height: 300px;
}

.blog__subtitle {
    --fontcolor: #111111;

    color: var(--fontcolor);
    font-size: 1em;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 1.2em;
}

.paragraph {
    white-space: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog__title {
    --fontcolor: #092e1c;
    --fontcolor: #111111;

    color: var(--fontcolor);
    font-size: 1.5em;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1em;
    text-decoration: none;
}

@media only screen and (min-width : 992px) {
    .blog {
        height: 600px
    }
}

@media only screen and (min-width : 1200px) {
    .blog {
        /* display: flex; */
        /* flex-direction: column; */
        /* justify-content: space-between; */
        /* height: 100%; */
        /* height: 50%; */
        /* flex-grow: 0; */
        /* flex-shrink: 1; */
        /* max-height: 100%; */
        /* overflow: none; */
        /* background-color: yellow; */
    }
}