.Navigation {
    background-color: var(--primaryNavBarBackgroundColor);
    padding: 10px 0px;
}

.Navigation__Link {
    color: var(--primaryNavBarLinkColor);
    font-size: var(--smFontSize);
    font-weight: var(--mdFontWeight);
    margin-right: 15px;
    text-decoration: none;
}

.Navigation__Link:hover {
    color: var(--primaryLinkHoverColor);
}

.Navigation__Link:last-child {
    margin-right: 0px;
}

.Navigation__Link--Active {
    color: var(--primaryLinkSelectedColor);
}

.Navigation__Link__Publett {
    align-items: center;
    display: flex;
    flex-direction: row;
    text-decoration: none;
}

.Navigation__Link__Publett img {
    margin-right: 5px;
    width: 20px;
}

.Navigation__Link__Publett p {
    color: var(--primaryNavBarColor);
    font-size: var(--smFontSize);
    font-weight: var(--mdFontWeight);
    margin: 0;
    padding: 0;
}


@media only screen and (min-width: 576px) {
    .Navigation__Link__Publett img {
        margin-right: 5px;
        width: 25px;
    }
}