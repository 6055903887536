@import url('https://fonts.googleapis.com/css2?family=Comforter&display=swap');

:root {
  /* --bgcolor: #cccccc; */

  --backgroundColor: #f3eef1;
  --backgroundColorDark: #e9e3e7;

  --primaryActionBackgroundColor: #f37c7c;
  --primaryActionLabelColor: #ffffff;

  --xsBorderRadius: 3px;
  --smBorderRadius: 5px;
  --mdBorderRadius: 7px;
  --lgBorderRadius: 10px;
  --xlBorderRadius: 15px;

  /* navigation bar */
  --primaryNavBarBackgroundColor: #212529;
  --primaryNavBarColor: #ffffff;
  --primaryNavBarLinkColor: #cccccc !important;

  --secondaryNavBarLinkColor: #aaaaaa !important;

  /* links */
  /* --primaryLinkColor: #cccccc !important; */
  --primaryLinkSelectedColor: #f37c7c;
  --primaryLinkHoverColor: #f39d9d;
  --secondaryLinkSelectedColor: #8c307e;
  --secondaryLinkHoverColor: #c361b5;

  /* titles */

  /* subtitles */

  --primaryBackgroundGradient: linear-gradient(90deg, rgba(240, 190, 168, 1) 0%, rgba(230, 178, 207, 1) 100%);
    
  --primaryImageBackgroundColor: #d4d4d4 ;

  /* font colors */
  --primaryFontBodyColor: #444444;
  --secondaryFontBodyColor: #999999;

  --xsLineHeight: 1rem;
  --smLineHeight: 1.3rem;
  --mdLineHeight: 1.6rem;
  --lgLineHeight: 1.9rem;
  --xlLineHieght: 2.2rem;
  
  /* font sizes */
  --xsFontSize: .7rem;
  --smFontSize: .85rem;
  --mdFontSize: 1rem;
  --lgFontSize: 1.1rem;
  --xlFontSize: 1.2rem;
  --h3FontSize: 1.3rem;
  --h2FontSize: 1.4rem;
  --h1FontSize: 1.5rem;
  --superFontSize: 4rem;

  --xsFontLetterSpacing: 0.1rem;
  --smFontLetterSpacing: 0.2rem;
  --mdFontLetterSpacing: 0.3rem;
  --lgFontLetterSpacing: 0.4rem;
  --xlFontLetterSpacing: 0.5rem;

  --xsFontWordSpacing: 0.1rem;
  --smFontWordSpacing: 0.2rem;
  --mdFontWordSpacing: 0.3rem;
  --lgFontWordSpacing: 0.4rem;
  --xlFontWordSpacing: 0.5rem;

  /* font weights */
  --xsFontWeight: 200;
  --smFontWeight: 300;
  --mdFontWeight: 400;
  --lgFontWeight: 500;
  --xlFontWeight: 600;
  --xxlFontWeight: 700;

  /* icons */
  --xsIconHeight: 20px;
  --smIconHeight: 25px;
  --mdIconHeight: 30px;
  --lgIconHeight: 35px;
  --xlIconHeight: 40px;

  /* @media (--narrow-window) { */
  /* narrow window styles */
  /* } */
  --xsBreak: 576px;
  --smBreak: 768px;
}

@media (min-width:576px) {
  :root {
    --xsFontSize: .7rem;
    --smFontSize: .8rem;
    --mdFontSize: 1rem;
    --lgFontSize: 1.2rem;
    --xlFontSize: 1.3rem;
    --h3FontSize: 1.3rem;
    --h2FontSize: 1.4rem;
    --h1FontSize: 1.5rem;
  }
}

@media (min-width:768px) {
  :root {
    --xsFontSize: .75rem;
    --smFontSize: .8rem;
    --mdFontSize: 1rem;
    --lgFontSize: 1.2rem;
    --xlFontSize: 1.4rem;
    --h3FontSize: 1.4rem;
    --h2FontSize: 1.6rem;
    --h1FontSize: 1.8rem;
  }
}

@media (min-width:992px) {
  :root {
    --xsFontSize: .7rem;
    --smFontSize: .8rem;
    --mdFontSize: 1rem;
    --lgFontSize: 1.2rem;
    --xlFontSize: 1.4rem;
    --h3FontSize: 1.6rem;
    --h2FontSize: 1.9rem;
    --h1FontSize: 2.2rem;}
}

@media (min-width:1200px) {
  :root {
    --xsFontSize: .7rem;
    --smFontSize: .8rem;
    --mdFontSize: 1rem;
    --lgFontSize: 1.2rem;
    --xlFontSize: 1.4rem;
    --h3FontSize: 1.8rem;
    --h2FontSize: 2.2rem;
    --h1FontSize: 2.6rem;}
}

.App {
  margin: 0;
  padding: 0;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */