.Introduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100px;
    padding: 40px;
    padding: 40px;
}

.Introduction__Appstoreicon {
    height: var(--lgIconHeight);
}

.Introduction__Appstorelink {
    display: inline-block;
    margin-right: 15px;
    margin-top: 10px;
}

.Introduction__Appstorelink:last-child {
    margin-right: 0px;
}

.Introduction__Buttons {
    margin-top: 10px;
}

.Introduction__Subtitle {
    font-size: var(--lgFontSize);
    font-weight: var(--xsFontWeight);
}

.Introduction__Title {
    font-size: var(--h1FontSize);
    font-weight: var(--smFontWeight);
    margin-bottom: 20px;
}

@media (min-width: 576px) {}

@media (min-width:768px) {
    .Introduction {
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (min-width:992px) {
    .Introduction__Buttons {
        margin-top: 20px;
    }
}

@media (min-width:1200px) {}