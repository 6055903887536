.pubcast {
    --bgcolor: #222222;
    background-color: var(--bgcolor);
    /* height: 300px; */
    /* margin-top: 10px; */
    margin-right: 0px;
    padding: 30px;
    border-radius: 5px;
    /* background-image: url('../../../public/assets/pubcast.jpeg'); */
    background-image: url('../../../public/assets/research.webp');
    background-position: right;
    background-size: cover;
    background-blend-mode:overlay;
}
.pubcast__availableon{
    padding-top: 2em;
}
.pubcast__availableon__title {
    color: #555555;
}
.pubcast__title {
    --fontcolor: #ffffff;
    font-size: 2em;
    font-weight: 600;
    color: var(--fontcolor);
}
.pubcast__subtitle {
    --fontcolor: #eeeeee;
    font-size: 1.4em;
    font-weight: 300;
    color: var(--fontcolor);
    /* margin-bottom: 1em; */
}
.pubcast__badge {
    margin-bottom: 2em;
}
.pubcast__body {
    --fontcolor: #eeeeee;
    font-size: 1em;
    font-weight: 300;
    color: var(--fontcolor);
}
.pubcast__body__episode {
    --fontcolor: #dddddd;
    font-size: .85em;
    font-weight: 300;
    color: var(--fontcolor);
    margin: 0;
}
.pubcast__body__date {
    --fontcolor: #dddddd;
    font-size: .7em;
    font-weight: 300;
    color: var(--fontcolor);
    margin: 0;
}
.pubcast__heading {
    display: flex;
    flex-direction: column;
}
.pubcast__heading__preview {
    color: red;
}
.pubcast__heading__preview__tile {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}
.pubcast__link {
    text-decoration: none;
    font-weight: 600;
    color: rgb(224, 51, 160);
    margin-top: 10px;
}

@media (min-width:768px){
    
}

@media (min-width:992px){
    .pubcast__heading {
        /* background-color: green; */
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width:1200px){
    
}