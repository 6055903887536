.Footer {
    background-color: var(--primaryNavBarBackgroundColor);
}

.Footer__Copyright {
    color: var(--secondaryNavBarLinkColor);
    font-size: var(--xsFontSize);
}

.Footer__Container {
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10px 0px;
    text-align: center;
}

.Footer__Link {
    color: var(--primaryNavBarLinkColor);
    font-size: var(--xsFontSize);
    margin-right: 15px;
    text-decoration: none;
}

.Footer__Link:hover {
    color: var(--primaryLinkHoverColor);
}

.Footer__Link:last-child {
    margin-right: 0px;
}

.Footer__Link--Active {
    color: var(--primaryLinkSelectedColor);
}
.Footer__Policies {
    margin-bottom: 30px;
}

@media screen and (min-width: 576px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 992px) {
    .Footer__Container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0px;
        text-align: left;
    }
    .Footer__Policies {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 1200px) {}