


.contact__section__heading {
    font-size: 16px;
    font-weight: 600;
}

.contact__letmeknow__check {
    margin-bottom: 15px;
    margin-top: 5px;
    margin-left: 10px;
}
.contact__submit {
    margin-top: 20px;
    padding: 20px 0px;
}
.contact__body {
    margin: 0px 20px;
}
.contact__requests {
    border-top: 1px dotted #aaaaaa;
    margin-top: 30px;
    padding-top: 30px;
}

.submit_button {
    background-color: #ef3c57;
    background-color: #f37c7c;
    border: 0px;
}
.submit_button:hover {
    background-color: #ef3c57;
    background-color: #f37c7c;
    border: 0px;
}

.contact_gradient {
    background: rgb(221,245,250);
    background: linear-gradient(40deg, rgba(221,245,250,1) 0%, rgba(255,247,245,1) 100%);
}