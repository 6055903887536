.Blogintroduction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
    margin-top: 0px;
    min-height: 100px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Blogintroduction__Subtitle {
    font-size: var(--lgFontSize);
    font-weight: var(--xsFontWeight);
}

.Blogintroduction__Title {
    font-size: var(--h1FontSize);
    font-weight: var(--smFontWeight);
    margin-bottom: 20px;
}

@media (min-width:576px) {}

@media (min-width:768px) {
    .Blogintroduction {
        min-height: 300px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

}

@media (min-width:992px) {}

@media (min-width:1200px) {}